.Card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
}

img {
    width: 100px;
    height: 100px;
    border-radius: 40px;
    object-fit: cover;
}

.profile__details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profile__details div {
    font-size: 1.2rem;
    color: green;
}


